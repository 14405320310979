import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import device from '../helpers/breakpoints';
import Layout from '../components/layout';
import Nav from '../components/nav';
import Contact from '../components/contact';
import Footer from '../components/footer';
import Anchor from '../helpers/anchor';

export const query = graphql`
  {
    site {
      siteMetadata {
        name
        title
        description
      }
    }
    image: file(relativePath: { eq: "devops.jpg" }) {
      publicURL
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jasper: file(relativePath: { eq: "dit_is_jasper.png" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo: file(relativePath: { eq: "symboltext-xprtz-white.svg" }) {
      publicURL
    }
    arrowdown: file(relativePath: { eq: "arrowdown.svg" }) {
      publicURL
    }
  }
`;

const BodyContainer = styled.div`
  max-width: 728px;
  padding: 0 1rem;
  margin: 8rem auto 4rem;

  @media ${device.tablet} {
    padding: 0 2rem;
    max-width: 960px;
  }

  @media ${device.laptop} {
    padding: 0 8rem;
    max-width: 1280px;
  }

  @media ${device.laptopL} {
    padding: 0 8rem;
    max-width: 1440px;
  }

  h1 {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.022em;
  }

  h2 {
    font-size: 26px;
    line-height: 1.18;
    letter-spacing: -0.022em;
    margin-top: 1.72em;

    + p {
      margin-top: 0.46em;
    }
  }

  p {
    font-size: 21px;
    line-height: 1.58;
    letter-spacing: -0.004em;
    margin-top: 2em;
  }
`;

const DevOpsPage = ({ data }) => {
  return (
    <Layout
      metadata={{ ...data.site.siteMetadata, image: data.image.publicURL }}
    >
      <Nav Root="/" Fixed="true" />
      <BodyContainer>
        <Anchor id="banner-anchor"></Anchor>
        <h1>Software-activiteiten onder de loep: de XPRTZ Checkup</h1>
        <p>
          Bij XPRTZ{' '}
          <span role="img" aria-label="love">
            ❤️
          </span>{' '}
          we van code en alles wat er daar omheen hangt: ontwikkelprocessen,
          automatiseren, DevOps, continuous integration/continuous deployment,
          cloud, code kwaliteit, testing, Domain Driven Design, noem maar op.
          Omdat dit echt in onze genen zit lanceren we een nieuwe service: de
          XPRTZ Checkup voor developers. Wij komen vrijblijvend langs bij jouw
          organisatie, persoonlijk of virtueel, om samen op
          software-ontwikkelingsgebied te ontdekken wat jouw organisatie goed
          doet, mogelijke knelpunten in kaart brengen en identificeren waar er
          nog winst valt te halen. Het beste van allemaal:{' '}
          <strong>je bepaalt zelf wat onze service voor jouw waard is!</strong>{' '}
          Je hoeft dus geen moeilijke budget vragen te stellen, een uitnodiging
          met de dag waarop we welkom zijn is meer dan voldoende.
        </p>
        <h2>Waarom XPRTZ</h2>
        <p>
          Bij XPRTZ zitten we in een unieke positie: we helpen veel klanten in
          verschillende branches en nemen daarbij vaak de rol van lead developer
          of sofware architect op. Daardoor zien we veel gebeuren en zijn we
          betrokken vanaf de analyse van nieuwe functionaliteit, over het bouwen
          van nieuwe software en het onderhoud van bestaande software tot aan de
          go-live ervan. We hebben een massa kennis die wij graag delen van
          developer tot developer.
        </p>
        <h2>Waar we naar kijken</h2>
        <p>
          We zullen op basis van direct contact met onze .NET experts antwoorden
          zoeken op vragen in verschillende categorieën. die we samen
          bepalen.Hierbij nemen we uiteraard de ambitie van jullie organisatie
          mee. We zullen onderwerpen behandelen zoals:
        </p>
        <ul>
          <li>
            <span role="img" aria-label="tooling">
              🧰
            </span>{' '}
            Tooling: welke tools worden gebruikt en zijn deze standaard binnen
            de organisatie?
          </li>
          <li>
            <span role="img" aria-label="processen">
              📈
            </span>{' '}
            Processen: ligt er een proces zoals scrum of kanban aan de grondslag
            van het ontwikkelproces?
          </li>
          <li>
            <span role="img" aria-label="automatisatie">
              🖥️
            </span>{' '}
            Automatisatie: worden de juiste platformen gebruikt: on-premise of
            cloud? En zijn er repetitieve handelingen te ontdekken?
          </li>
          <li>
            <span role="img" aria-label="cloud">
              ☁️
            </span>{' '}
            Cloud: hoe kan de cloud voor versnelling zorgen en jullie
            organisatie een grotere mate van flexibiliteit bieden? Haal je wel
            het maximale uit de clouddiensten die je afneemt?
          </li>
          <li>
            <span role="img" aria-label="innovatie">
              💰
            </span>{' '}
            Innovatie: in hoeverre zijn jullie in staat te blijven innoveren in
            een periode van verandering of wanneer het budget even niet meezit?
          </li>
        </ul>
        <h2>Wat je mag verwachten</h2>
        <p>
          Geen academisch rapport met verkoopdoelstellingen maar wel open en
          eerlijke aanbevelingen die je meteen in de praktijk kan toepassen.
        </p>
        <ul>
          <li>
            Wij baseren ons op vier stadia van competentie om te{' '}
            <strong>ontdekken</strong> waar je je als organisatie bevindt, die
            gaat van <i>ik weet niet wat ik niet weet</i> tot{' '}
            <i>ik werk met de flow mee vanwege mijn kennis.</i>
          </li>
          <li>
            Daarna kunnen we aan de hand van doelstellingen{' '}
            <strong>bepalen</strong> waar de organisatie naar toe kan groeien.
          </li>
          <li>
            Als laatste stap wordt er een <strong>overzicht</strong> uitgewerkt
            om de beoogde doelstellingen te realiseren. Wij geloven in een agile
            aanpak en geven graag de regie van hoe deze stappen te realiseren
            aan jullie team. Wij helpen hier uiteraard graag bij!
          </li>
        </ul>
        <h2>Ok, wanneer zullen we starten?</h2>
        <p>
          Mooi dat je enthousiast bent, wij zijn dat namelijk ook. De eerste
          stap is contact leggen, dit kan eenvoudig met een belletje naar Jasper
          of een e-mail. Zo gemakkelijk gaat dat!
        </p>
        <div
          css={css`
            display: flex;
            justify-content: center;
            min-height: 250px;
            margin: 1rem auto;
          `}
        >
          <img
            src={data.jasper.sharp.fluid.src}
            alt="Dit is Jasper"
            height="250px"
          />
        </div>
      </BodyContainer>
      <Contact />
      <Footer />
    </Layout>
  );
};

export default DevOpsPage;
